import ApiBase from './ApiBase'
import apiUrl from '../config/api'
import {RequestResult} from '@/utils/interface'

//params
interface SendSmsParams {
  mobile: string,
  platform: string,
  event: string
}


// response

class ApiCommon extends ApiBase {
  protected url: string = apiUrl.hClean;
  protected prefix: string = ''
  protected resultDataKey: string = 'data';

  public sendSms(data: SendSmsParams):Promise<RequestResult<unknown>> {
    return this.post('/delivery/sms', data)
  }

}

export const apiCommon = new ApiCommon()
