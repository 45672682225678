import {Toast, Dialog} from "vant";
import {apiCommon} from "@API/ApiCommon";

export interface ISms{
  time: number
  smsBtnDis: boolean
  setIntervalCountDown : any

  sendSms(a: string, b: string) : Promise<boolean>,
  countDown() :void
  countDownInit() :void
}

export class Sms implements ISms {
  time = 60
  smsBtnDis = false
  setIntervalCountDown = null as any

  // 发送验证码
  async sendSms(mobile :string, event: string): Promise<any> {
    if (this.smsBtnDis) {
      return false
    }
    let checkMobile :number | string = parseInt(mobile) // 转int移除string
    checkMobile = mobile.toString() // 重新转回来判断长度
    console.log(checkMobile.length)
    if (checkMobile.length !== 11) {
      Toast.fail('请输入正确的手机号码');
      return false
    }

    this.smsBtnDis = true
    this.countDown()

    try {
      const res = await apiCommon.sendSms({
        platform: 'delivery',
        mobile,
        event
      })
      return res.data
    }catch (e) {
      await Dialog({
        title: '发送验证码失败',
        message: e.message
      })
      this.countDownInit()
    }
  }

  // 倒计时
  countDown():void {
    this.setIntervalCountDown = setInterval(() => {
      if(this.time <= 0) {
        this.countDownInit()
      }else {
        this.time --
      }
    }, 1000)
  }

  // 计时器初始化
  countDownInit():void {
    clearInterval(this.setIntervalCountDown)
    this.time = 60
    this.smsBtnDis = false
  }
}

